var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items1,"fields":_vm.fields1,"items-per-page":_vm.small ? 5 : 5,"dark":_vm.dark,"clickable-rows":true,"sorter":{ external: true, resetable: true },"pagination":""},scopedSlots:_vm._u([{key:"is_active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CSwitch',{staticClass:"mt-1 ml-2 mr-2",attrs:{"color":"primary","id":item.id,"checked":item.is_active,"shape":"pill"},on:{"update:checked":function($event){return _vm.activeUser(item)}}})],1)]}},{key:"Action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"secondary","size":"sm","id":item.id},on:{"click":function($event){return _vm.editUser(item)}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"danger","size":"sm","id":item.id},on:{"click":function($event){return _vm.openModal(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])}),_c('CDataTable',{attrs:{"hover":_vm.hover,"striped":_vm.striped,"border":_vm.border,"small":_vm.small,"fixed":_vm.fixed,"items":_vm.items2,"fields":_vm.fields2,"items-per-page":_vm.small ? 5 : 5,"dark":_vm.dark,"clickable-rows":true,"sorter":{ external: true, resetable: true },"pagination":""},scopedSlots:_vm._u([{key:"is_active",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('CSwitch',{staticClass:"mt-1 ml-2 mr-2",attrs:{"color":"primary","id":item.id,"checked":item.is_active,"shape":"pill"},on:{"update:checked":function($event){return _vm.activeUser(item)}}})],1)]}},{key:"Action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex"},[_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"secondary","size":"sm","id":item.id},on:{"click":function($event){return _vm.editUser(item)}}},[_c('CIcon',{attrs:{"name":"cil-pencil"}})],1),_c('CButton',{staticClass:"mr-2 mb-2",attrs:{"color":"danger","size":"sm","id":item.id},on:{"click":function($event){return _vm.openModal(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])}),_c('CModal',{attrs:{"title":"Delete farmer","show":_vm.myModal,"size":"sm"},on:{"update:show":function($event){_vm.myModal=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.myModal= false}}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v("Delete")])]},proxy:true}])},[_vm._v(" Are you sure you want to proceed? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }