<template>
    <div>

        <!-- Sensor data table -->

        <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items1"
            :fields="fields1"
            :items-per-page="small ? 5 : 5"
            :dark="dark"
            :clickable-rows="true"
            :sorter="{ external: true, resetable: true }"
            pagination
        >


            <template #is_active="{ item }">
                <td>
                    <CSwitch
                        class="mt-1 ml-2 mr-2"
                        color="primary"
                        :id="item.id"
                        :checked="item.is_active"
                        @update:checked="activeUser(item)"
                        shape="pill"
                    />
                </td>
            </template>

            <template #Action="{ item }">
                <td class="d-flex">
                    <CButton
                        color="secondary"
                        size="sm"
                        class="mr-2 mb-2"
                        :id="item.id"
                        @click="editUser(item)"
                        ><CIcon name="cil-pencil"
                    /></CButton>
                    <CButton
                        color="danger"
                        size="sm"
                        class="mr-2 mb-2"
                        :id="item.id"
                        @click="openModal(item)"
                        ><CIcon name="cil-trash"
                    /></CButton>
                </td>
            </template>
        </CDataTable>


        <!-- Soil-chemical parameter data -->

        <CDataTable
            :hover="hover"
            :striped="striped"
            :border="border"
            :small="small"
            :fixed="fixed"
            :items="items2"
            :fields="fields2"
            :items-per-page="small ? 5 : 5"
            :dark="dark"
            :clickable-rows="true"
            :sorter="{ external: true, resetable: true }"
            pagination
        >


            <template #is_active="{ item }">
                <td>
                    <CSwitch
                        class="mt-1 ml-2 mr-2"
                        color="primary"
                        :id="item.id"
                        :checked="item.is_active"
                        @update:checked="activeUser(item)"
                        shape="pill"
                    />
                </td>
            </template>

            <template #Action="{ item }">
                <td class="d-flex">
                    <CButton
                        color="secondary"
                        size="sm"
                        class="mr-2 mb-2"
                        :id="item.id"
                        @click="editUser(item)"
                        ><CIcon name="cil-pencil"
                    /></CButton>
                    <CButton
                        color="danger"
                        size="sm"
                        class="mr-2 mb-2"
                        :id="item.id"
                        @click="openModal(item)"
                        ><CIcon name="cil-trash"
                    /></CButton>
                </td>
            </template>
        </CDataTable>

        <!-- Modal used to delete user -->

        <CModal
            title="Delete farmer"
            :show.sync="myModal"
            size="sm"
            >
                Are you sure you want to proceed?
                <template #footer>
                    <CButton @click="myModal= false" color="danger">Cancel</CButton>
                    <CButton @click="deleteUser()" color="success">Delete</CButton>
            </template>
        </CModal>

    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
// const farmerNamesUrl = URL + `${"profile/farmer/names/"}`;

export default {
    name: "DataTable",
    data() {
        return{
            myModal: false,
            deleteElement: null,
        }
    },
    methods: {

        openModal(item){
            this.myModal= true;
            this.deleteElement= item;
        }
    },

    props: {
        items1: Array,
        items2: Array,

        fields1: {
            type: Array,
            default() {
                return {
                    items: Array,
                    fields: {
                        type: Array,
                    }
                }; 
            },
        },


        fields2: {
            type: Array,
            default() {
                return {
                    items: Array,
                    fields: {
                        type: Array,
                    }
                }; 
            },
        },



        caption: {
            type: String,
            default: "DataTable",
        },
        hover: Boolean,
        striped: Boolean,
        border: Boolean,
        small: Boolean,
        fixed: Boolean,
        dark: Boolean,
    },
};
</script>
