<template>
    <div>

        <!-- Alert message on error -->
        <div>
            <CAlert
                color="danger"
                :show.sync="checkError"
                class="alert-dismissible"
            >
                {{ this.errorMessage }}
            </CAlert>
        </div>


        <!-- Sensor data table -->
        <div>
            <CCard>
                <CCardHeader>
                    <strong> Upload sensor data </strong> 
                </CCardHeader>

                <CRow class="mt-4 ml-2">
                    <div class="col-6">
                        <CSelect
                            placeholder="Select Farmer"
                            :options="farmerNames"
                            v-model="farmerID"
                        />
                    </div> 
                    <div class="mt-1">
                        <input
                            id="fileUpload"
                            type="file"
                            @change="loadFile"
                            hidden
                        />
                        <CButton
                            v-if="file === null"
                            size="sm"
                            color="primary"
                            @click="chooseFile"
                        >
                            <CIcon name="cil-cloud-upload" />
                            Upload file
                        </CButton>
                        <CButton
                            v-else
                            size="sm"
                            class="save-button"
                            @click="saveFile"
                        >
                            <CIcon name="cil-cloud-upload" />
                            Save file
                        </CButton>
                    </div>
                </CRow>

                <CCardBody>
                    <div class="table-responsive">
                        <CDataTable
                            :hover="true"
                            :striped="true"
                            :border="true"
                            :small="small"
                            :fixed="true"
                            :items="historyItems"
                            :fields="fields1"
                            :items-per-page="small ? 5 : 5"
                            :clickable-rows="true"
                            :sorter="{ external: true, resetable: true }"
                            pagination
                        >
                            <template #Action1="{ item }">
                                <td class="d-flex">
                                    <CButton
                                        color="danger"
                                        size="sm"
                                        class="mr-2 mb-2"
                                        :id="item.id"
                                        @click="openModal(item, 1)"
                                        ><CIcon name="cil-trash"
                                    /></CButton>
                                </td>
                            </template>
                        </CDataTable>

                        <CModal
                            title="Delete CSV file"
                            :show.sync="myModal"
                            size="sm"
                        >
                            Are you sure you want to proceed?
                            <template #footer>
                                <CButton @click="myModal = false" color="danger"
                                    >Cancel</CButton
                                >
                                <CButton @click="deleteFile()" color="success"
                                    >Delete</CButton
                                >
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
            </CCard>
        </div>

        <!-- Soil-chemical parameter data -->
        <div>
            <CCard>
                <CCardHeader>
                    <strong> Upload soil-chemical parameter data </strong> 
                </CCardHeader>
                <CRow class="mt-4 ml-2">
                    <div class="col-6">
                        <CSelect
                            placeholder="Select Farmer"
                            :options="farmerNames"
                            v-model="farmerID2"
                        />
                    </div>                    
                    <div class="mt-1">
                        <input
                            id="fileUpload2"
                            type="file"
                            @change="loadFile2"
                            hidden
                        />
                        <CButton
                            v-if="file2 === null"
                            size="sm"
                            color="primary"
                            @click="chooseFile2"
                        >
                            <CIcon name="cil-cloud-upload" />
                            Upload file
                        </CButton>
                        <CButton
                            v-else
                            size="sm"
                            class="save-button"
                            @click="saveFile2"
                        >
                            <CIcon name="cil-cloud-upload" />
                            Save file
                        </CButton>
                    </div>
                </CRow>

                <CCardBody>
                    <div class="table-responsive">
                        <CDataTable
                            :hover="true"
                            :striped="true"
                            :border="true"
                            :small="small"
                            :fixed="true"
                            :items="historyItems"
                            :fields="fields2"
                            :items-per-page="small ? 5 : 5"
                            :clickable-rows="true"
                            :sorter="{ external: true, resetable: true }"
                            pagination
                        >
                            
                            <template #Action2="{ item }">
                                <td class="d-flex">
                                    <CButton
                                        color="danger"
                                        size="sm"
                                        class="mr-2 mb-2"
                                        :id="item.id"
                                        @click="openModal(item, 2)"
                                        ><CIcon name="cil-trash"
                                    /></CButton>
                                </td>
                            </template>
                        </CDataTable>

                        <CModal
                            title="Delete CSV file"
                            :show.sync="myModal"
                            size="sm"
                        >
                            Are you sure you want to proceed?
                            <template #footer>
                                <CButton @click="myModal = false" color="danger"
                                    >Cancel</CButton
                                >
                                <CButton @click="deleteFile()" color="success"
                                    >Delete</CButton
                                >
                            </template>
                        </CModal>
                    </div>
                </CCardBody>
            </CCard>
        </div>


        

    </div>
</template>

<script>
import axios from "axios";

const URL = axios.defaults.baseURL;
const farmerNamesUrl = URL + `${"profile/farmer/names/"}`;
const farmerCSVUrl = URL + `${"profile/farmer/csvupload/"}`;
const csvUploadHistoryUrl = URL + `${"profile/csvupload/history/"}`;

import CustomTable from "./DataTable.vue";

export default {
    name: "Data",
    components: { CustomTable },

    data() {
        return {
            farmerNames: [],
            farmerID: null,
            farmerID2: null,
            file: null,
            file2: null,
            fileID: null,
            historyItems: [],
            deleteElement: null,
            
            myModal: false,
            deleteElement: null,
            checkError: false,
            errorMessage: "",
            fields1: [
                {
                    key: "full_name",
                    label: "Farmer name",
                },
                {
                    key: "name",
                    label: "Sensor data",
                },
                {
                    key: "type",
                    label: "Data type",
                },
                {
                    key: "uploaded_at",
                    label: "Last updated",
                },
                {
                    key: "Action1",
                    label: "Action",
                },
            ],

            fields2: [
                {
                    key: "full_name",
                    label: "Farmer name",
                },
                {
                    key: "name2",
                    label: "Soil-chemical parameter data ",
                },
                {
                    key: "type2",
                    label: "Data type",
                },
                {
                    key: "uploaded_at2",
                    label: "Last updated",
                },
                {
                    key: "Action2",
                    label: "Action",
                },
            ],
        };
    },

    methods: {
        openModal(item, fileID) {
            this.myModal = true;
            this.fileID = fileID;
            this.deleteElement = item;
        },

        getNamesAPI() {
            this.axios
                .get(farmerNamesUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.farmerNames = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                    }
                });
        },

        getUploadHistory() {
            this.axios
                .get(csvUploadHistoryUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: "all" },
                })
                .then((response) => {
                    // console.log(response.data.context);
                    this.historyItems = response.data.context;
                })
                .catch((error) => {
                    this.checkError = true;
                    try {
                        this.errorMessage = error.response.data.message;
                        console.log(error.response.data.message);
                    }
                    catch {
                        this.errorMessage = error.message;
                        console.log(error.message);
                    }
                });
        },
        chooseFile() {
            document.getElementById("fileUpload").click();
        },
        loadFile(event) {
            this.file = event.target.files[0];
        },
        saveFile() {
            let form_data = new FormData();
            if (this.file != null) {
                    form_data.append("csv_file1", this.file);
                }
            this.file = null;
            this.saveUserData(form_data, this.farmerID, 1);
        },

        chooseFile2() {
            document.getElementById("fileUpload2").click();
        },
        loadFile2(event) {
            this.file2 = event.target.files[0];
        },
        saveFile2() {
            let form_data = new FormData();
            if (this.file2 != null) {
                    form_data.append("csv_file2", this.file2);
                }
            this.file2 = null;
            this.saveUserData(form_data, this.farmerID2, 2);
        },
        saveUserData(form_data, farmerID,fileID) {
            this.axios
                .put(farmerCSVUrl, form_data, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: farmerID, file_id: fileID },
                })
                .then((response) => {
                    console.log(response.data.message);
                    document.getElementById("fileUpload").value = null;
                    document.getElementById("fileUpload2").value = null;
                    this.getUploadHistory();
                })
                .catch((error) => {
                    document.getElementById("fileUpload").value = null;
                    document.getElementById("fileUpload2").value = null;
                    // alert(error.response.data.message);
                    this.checkError = true;
                    this.errorMessage = error.response.data.message;
                });
        },
        deleteFile() {
            this.axios
                .delete(farmerCSVUrl, {
                    headers: {
                        Authorization: `token ${localStorage.getItem("token")}`,
                    },
                    params: { id: this.deleteElement.id, file_id: this.fileID },
                })
                .then((response) => {
                    this.myModal = false;
                    this.deleteElement = null;
                    this.fileID = null;
                    this.getUploadHistory();
                })
                .catch((error) => {
                    this.myModal = false;
                    this.checkError = true;
                    this.errorMessage = error.response.data.message;
                    // alert(error.response.data.message);
                });
        },
    },

    watch:{
        checkError(newValue){
            if (newValue)
              setTimeout(() => this.checkError = false, 2000)  
        }
    },

    props: {
        small: Boolean,
    },

    beforeMount() {
        this.getNamesAPI();
        this.getUploadHistory();
        // this.getAPI();
    },
};
</script>
